import { Product, SelectionGroup } from "./catalog";
import { ICartItem, ICartItemSelectionGroup } from "../decoders";
import { Copyable } from "../../common_util";
import { IdTarget } from "src/storefront/types/id_target";
import { OutOfStockResolutionPreference } from "src/storefront/types/out_of_stock_resolution_preference";

type ComparableCartItem = Pick<CartItem, "key" | "keyPrefix" | "name" | "description" | "price" | "mealPreferences" | "outOfStuckResolutionPreference" > & { quantity?: number; selectionGroups: ComparableCartItemSelectionGroup[] }
type ComparableCartItemSelectionGroup = Pick<CartItemSelectionGroup, "key" | "name"> & { cartItems: ComparableCartItem[] };

export class Cart {
  readonly uuid: string;
  readonly cartItems: CartItem[];
  readonly deliveryFee: number;
  readonly subscriptionDeliveryFeeSavings: number;
  readonly serviceFee: number;
  readonly discount: number;
  readonly eligibleFreeProductKeys: string[]

  constructor(
    uuid: string,
    cartItems: CartItem[],
    deliveryFee: number,
    subscriptionDeliveryFeeSavings: number,
    serviceFee: number,
    discount: number,
    eligibleFreeProductKeys?: string[]
  ) {
    this.uuid = uuid;
    this.cartItems = cartItems;
    this.deliveryFee = deliveryFee;
    this.subscriptionDeliveryFeeSavings = subscriptionDeliveryFeeSavings
    this.serviceFee = serviceFee;
    this.discount = discount;
    this.eligibleFreeProductKeys = eligibleFreeProductKeys || [];
  }

  total() : number {
    return this.subtotal() + this.deliveryFee - this.discount + this.serviceFee - this.subscriptionDeliveryFeeSavings;
  }

  subtotal() : number {
    return this.cartItems.reduce((accl, ci) => ci.subtotal() + accl, 0);
  }

  cartItemCount(): number {
    return this.cartItems.reduce((acc, val) => acc + val.quantity, 0);
  }

  getCountOfItemInCart(product: Product): number {
    return this.cartItems.filter((ci: CartItem) => {
      return ci.key == product.key
    }).map(i => i.quantity).reduce((sum, currentValue) => sum + currentValue, 0)
  }
}

export class CartItem extends Copyable {
  readonly key: string;
  readonly keyPrefix: string[];
  readonly name: string;
  readonly description?: string;
  readonly price: number;
  readonly quantity: number;
  readonly mealPreferences?: string;
  readonly selectionGroups: CartItemSelectionGroup[];
  readonly idTarget: IdTarget;
  readonly restrictions: string[];
  readonly outOfStuckResolutionPreference: OutOfStockResolutionPreference;

  constructor(
    key: string,
    keyPrefix: string[],
    name: string,
    price: number,
    quantity: number,
    selectionGroups: CartItemSelectionGroup[],
    idTarget: IdTarget,
    outOfStuckResolutionPreference: OutOfStockResolutionPreference,
    description?: string,
    mealPreferences?: string,
    restrictions?: string[],
  ) {
    super();

    this.key = key;
    this.keyPrefix = keyPrefix;
    this.name = name;
    this.description = description;
    this.price = price;
    this.quantity = quantity;
    this.mealPreferences = mealPreferences;
    this.selectionGroups = selectionGroups;
    this.idTarget = idTarget;
    this.restrictions = restrictions || [];
    this.outOfStuckResolutionPreference = outOfStuckResolutionPreference;
  }

  static fromProduct(product: Product) : CartItem {
    return new CartItem(
      product.key,
      product.keyPrefix,
      product.title,
      product.price,
      1,
      [],
      new IdTarget(),
      OutOfStockResolutionPreference.Default,
      product.description,
      undefined,
      product.restrictions,
    );
  }

  toJson() : ICartItem {
    return {
      id: this.idTarget.value,
      key: this.key,
      key_prefix: this.keyPrefix,
      name: this.name,
      price: this.price,
      tags: [],
      quantity: this.quantity,
      selection_groups: this.selectionGroups.map(sg => sg.toJson()),
      description: this.description,
      meal_preferences: this.mealPreferences,
      restrictions: this.restrictions,
      out_of_stock_resolution_preference: this.outOfStuckResolutionPreference.type,
    };
  }

  subtotal(): number {
    return (this.price + this.selectionGroups.reduce((accl, sg) => accl + sg.subtotal(), 0)) * this.quantity;
  }

  extrasDescription() : string {
    return this.selectionGroups.map(sg => sg.selectionGroupDescription()).filter(elm => elm).join(", ")
  }

  extrasFullDescription() : string[] {
    return this.selectionGroups.map(sg => sg.selectionGroupDescription()).filter(elm => elm)
  }

  isStackableWith(cartItem: CartItem): boolean {
    return JSON.stringify(this.attributesForComparison({ compareQuantity: false })) === JSON.stringify(cartItem.attributesForComparison({ compareQuantity: false }));
  }

  attributesForComparison({ compareQuantity = false }): ComparableCartItem {
    return {
      key: this.key,
      keyPrefix: this.keyPrefix,
      name: this.name,
      price: this.price,
      ...(compareQuantity) && { quantity: this.quantity },
      selectionGroups: this.selectionGroups.map(sg => sg.attributesForComparison()),
      description: this.description || undefined, // Normalise falsy string values to undefined
      mealPreferences: this.mealPreferences || undefined, // Normalise falsy string values to undefined
      outOfStuckResolutionPreference: this.outOfStuckResolutionPreference,
    }
  }
}

export class CartItemSelectionGroup extends Copyable {
  key: string;
  keyPrefix: string[];
  name: string;
  cartItems: CartItem[];

  constructor(
    key: string,
    keyPrefix: string[],
    name: string,
    cartItems: CartItem[],
  ) {
    super();

    this.key = key;
    this.keyPrefix = keyPrefix;
    this.name = name;
    this.cartItems = cartItems;
  }

  static fromSelectionGroup(selectionGroup: SelectionGroup): CartItemSelectionGroup {
    return new CartItemSelectionGroup(
      selectionGroup.key,
      selectionGroup.keyPrefix,
      selectionGroup.title,
      []
    );
  }

  subtotal(): number {
    return this.cartItems.reduce((accl, sg) => accl + sg.subtotal(), 0);
  }

  selectionGroupDescription() : string {
    if (this.cartItems.length == 0) {
      return "";
    }

    return this.name + ": " + this.cartItems.map(sge => {
      let description = sge.name;
      const extrasDescription = sge.extrasDescription();
      if (extrasDescription.length) description += `, ${sge.extrasDescription()}`;
      if (sge.quantity > 1) description += ' \u00D7 ' + sge.quantity

      return description;
    }).join(", ")
  }

  toJson() : ICartItemSelectionGroup {
    return {
      key: this.key,
      key_prefix: this.keyPrefix,
      name: this.name,
      cart_items: this.cartItems.map(ci => ci.toJson()),
    };
  }

  attributesForComparison(): ComparableCartItemSelectionGroup {
    return {
      key: this.key,
      name: this.name,
      cartItems: this.cartItems.map(ci => ci.attributesForComparison({ compareQuantity: true })),
    }
  }
}
